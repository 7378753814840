// import { Link } from '@chakra-ui/react';
import React, { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import styles from './SecondaryLayout.module.css';

const SecondaryLayout = ({ mqttInstance }) => {
  return (
    <Fragment>
      {/* <header>
        <Link as={ReactLink} to='/'>
          BrandLogo
        </Link>
      </header> */}
      <main className={styles.main}>
        <Outlet context={{ mqttInstance }} />
      </main>
      {/* <footer></footer> */}
    </Fragment>
  );
};

export default SecondaryLayout;

import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

export const GuardRoute = (props) => {
  const auth = useSelector((state) => state.auth);
  const location = useLocation();

  if (!auth.allowed) {
    return <Navigate to="/welcome" state={{ from: location }} replace />;
  }

  return props.children;
};

import React, { useEffect } from 'react';
import { Box, Container, Image, Text } from '@chakra-ui/react';
import { Page } from '../../../components/common';
import { useDispatch, useSelector } from 'react-redux';
import { setAllowed } from '../services/authSlice';
import { useLocation, useNavigate } from 'react-router-dom';

function SplashPage() {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      dispatch(setAllowed(true));
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (auth.allowed) {
      const search = location.state?.from.search;
      const id = new URLSearchParams(search).get('id');
      if (id) {
        document.cookie = 'requestId=' + id;
      }

      const from = location.state?.from?.pathname || '/';
      navigate(from);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.allowed]);

  return (
    <Page title='Splash Page'>
      <Container px='4' maxW='container.sm'>
        <Box
          h='100vh'
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          position='relative'
        >
          <Box
            display='flex'
            flexDirection='column'
            alignItems='flex-end'
            transform='translate(0px, -50px)'
          >
            <Image
              boxSize='full'
              objectFit='contain'
              src='/logo-dashboard.png'
              alt='logo'
            />
            <Text
              fontSize='md'
              as='samp'
              mr='7px'
              transform='translate(0px, -160px)'
            >
              TRACKING LOGISTIC V 1.0
            </Text>
          </Box>
        </Box>
      </Container>
    </Page>
  );
}

export default SplashPage;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allowed: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAllowed: (state, action) => {
      state.allowed = action.payload;
    },
    // logout: (state) => {
    //   state.user = false;
    // },
  },
});

export const { setAllowed } = authSlice.actions;

export default authSlice.reducer;

import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import authReducer from '../modules/auth/services/authSlice';
import dashboardSlice from '../modules/dashboard/services/dashboard-slice';
import dashboardApi from '../modules/dashboard/services/dashboardApi';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    dashboard: dashboardSlice,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(dashboardApi.middleware),
});

setupListeners(store.dispatch);

import dayjs from 'dayjs';

export const dashboardDataMapper = (objs) => {
  return objs.map((obj) => ({
    batchId: obj['ID Lot'],
    driverId: obj['ID Driver'],
    truckName: obj['TRUCK NAME'],
    driverName: obj['Driver Name'],
    driverPhone: obj['Driver phone'],
    driverWhatapps: obj['Driver whatapps'],
    driverTelegram: obj['Driver telegram'],
    driverMail: obj['Driver mail'],
    clientName: obj['Client name'],
    steOrder: obj['STE Order'],
    amount: obj['Amount'],
    clientPhone: obj['Client phone'],
    clientWhatapps: obj['Client whatapps'],
    clientTelegram: obj['Client telegram'],
    clientMail: obj['Client mail'],
    volume: obj['Volume'],
    timeEnd: obj['Time end'],
  }));
};

export const trackingDataMapper = (obj) => {
  const convertStatus = (event) => {
    if (event === 'connected') {
      return 'connecté';
    }
    if (event === 'loading') {
      return 'chargement';
    }
    if (event === 'paid') {
      return 'payé';
    }
    if (event === 'lvSigned') {
      return 'LV signé';
    }
    if (event === 'completed') {
      return 'supplement';
    }
    if (event === 'transport') {
      return 'livraison';
    }
    if (event === 'disconnected') {
      return 'déconnecté';
    }
    if (event === 'no-road' || event === 'routing') {
      return 'location';
    }
  };

  const coords = obj.message.coords;
  return {
    id: obj.message.batchId,
    driver: obj.message.vehicleId,
    data: {
      event: convertStatus(obj.message.status),
      datetime: dayjs().format('YYYY-MM-DD HH:mm'),
      loc: [coords[0].lat, coords[0].lng],
    },
  };
};

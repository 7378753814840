import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { dashboardDataMapper, trackingDataMapper } from '../utils/dataMapper';

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_TRACKING_MOVE_BASE_URL}/v1/tracking`,
    prepareHeaders: (headers) => {
      const requestId = window.getCookie('requestId');
      headers.set('X-Request-ID', requestId);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchAllInfo: builder.mutation({
      query: () => ({
        url: '/dashboard/list',
        method: 'POST',
      }),
      transformResponse: (res) => dashboardDataMapper(res.data),
    }),
    sendAllTracking: builder.mutation({
      query: (body) => ({
        url: '/log',
        method: 'POST',
        body: trackingDataMapper(body),
      }),
    }),
  }),
});

export default dashboardApi;

export const { useFetchAllInfoMutation, useSendAllTrackingMutation } =
  dashboardApi;

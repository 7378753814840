/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PrimaryLayout, SecondaryLayout } from './layouts';
import AuthRoutes from './modules/auth/routes';
import { GuardRoute } from './router';
import { v4 as uuidv4 } from 'uuid';
import { useMqtt } from './modules/dashboard/hooks';

const Dashboard = React.lazy(() => import('./modules/dashboard/pages'));

window.getCookie = function (name) {
  var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) return match[2];
};

const clientId = window.getCookie('clientId') || uuidv4();
document.cookie = 'clientId=' + clientId;

const clientIdA = window.getCookie('clientIdA') || uuidv4();
document.cookie = 'clientIdA=' + clientIdA;

function App() {
  const mqttInstance = useMqtt(process.env.REACT_APP_BROKER_HOST, {
    clientId,
    clean: false,
  });
  const { isSubed, connectStatus, mqttSub } = mqttInstance;
  const {
    connectStatus: connectStatusA,
    isSubed: isSubedA,
    mqttSub: mqttSubA,
    mqttPublish: mqttPublishA,
  } = useMqtt(process.env.REACT_APP_BROKER_HOST, {
    clientId: clientIdA,
    clean: false,
  });

  useEffect(() => {
    mqttSub({ topic: 'tracking', qos: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectStatus]);

  useEffect(() => {
    if (connectStatusA === 'connected') {
      mqttSubA({ topic: 'dashboard', qos: 1 });
    }
  }, [connectStatusA]);

  useEffect(() => {
    if (isSubedA && isSubed) {
      const payload = {
        status: 'connected',
      };
      mqttPublishA({ topic: 'dashboard', qos: 1, payload });

      window.addEventListener('beforeunload', (e) => {
        const payload = {
          status: 'disconnected',
        };
        mqttPublishA({ topic: 'dashboard', qos: 1, payload });
        e.preventDefault();
      });
    }
  }, [isSubedA, isSubed]);

  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route element={<PrimaryLayout />}></Route>
          <Route element={<SecondaryLayout mqttInstance={mqttInstance} />}>
            <Route index path='/*' element={<AuthRoutes />} />
            <Route
              index
              path='/'
              element={
                <GuardRoute>
                  <Dashboard />
                </GuardRoute>
              }
            />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
